.schedule-modal{
  .schedule-form{
    display: block;
    @media #{$small-and-up} {
      @include flexbox();
      @include flex-direction(row);
    }
    .form-area{
      @media #{$small-and-up} {
        order: 2;
        -ms-flex-order: 2;
        padding-left: 2rem;
        @include flexbox();
        @include flex-direction(column);
      }
    }
    .image-area{
      text-align: center;
      padding: 1rem 0;
      @media #{$small-and-up} {
        padding: 1rem 0;
        width: 220px;
        order: 1;
        -ms-flex-order: 1;
        @include flexbox();
        @include flex-direction(column);
      }
      .vehicle-img{
        width: 200px;
      }
    }
  }
}