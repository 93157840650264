$header-background: rgba($color-white, .9);
$nav-height: 100px;
$nav-height-responsive: 64px;

body{
  padding-top: $nav-height-responsive;
  @media #{$medium-and-up} {
    padding-top: $nav-height;
  }
}

.main-header{
  height: $nav-height-responsive;
  width: 100%;
  background: $header-background;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  padding: .25rem;
  border-bottom: 1px solid color('gray', 'lighten-4');
  @media #{$medium-and-up} {
    height: $nav-height;
    padding: 1rem;
    @include flex-wrap(nowrap);
  }
  .brand{
    @media #{$medium-and-up} {
      order: 1;
      -ms-flex-order: 1;
      padding-right: 3rem;
      border-right: 1px solid color('gray', 'lighten-3');
    }
    img{
      width: 100px;
      height: auto;
      @media #{$medium-and-up} {
        width: 160px;
      }
    }
  }
  .cta-button{
    @include flex(1);
    @media #{$medium-and-up} {
      @include flex(initial);
      order: 3;
      -ms-flex-order: 3;
    }
  }
  .responsive-button{
    width: 44px;
    height: 52px;
    position: relative;
    background: $color-white;
    border: 1px solid color('gray', 'lighten-2');
    border-radius: $radio-small;
    display: block;
    @media #{$medium-and-up} {
      display: none;
    }
    &:after, &:before{
      content: '';
      display: block;
      left: 8px;
      width: 26px;
      height: 4px;
      position: absolute;
      background: color('gray', 'lighten-2');
    }
    &:after{
      top: 15px;
    }
    &:before{
      bottom: 15px;
    }
  }
  nav{
    position: fixed;
    left: 0;
    top: 0;
    margin-top: -1400px;
    background: $color-white;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    z-index: 300;
    @include transition(all .4s);
    &.active{
      margin-top: 0;
      @include flexbox();
      @include flex(1);
      @include flex-direction(column)
    }
    @media #{$medium-and-up} {
      margin-top: 0;
      position: relative;
      overflow: inherit;
      z-index: inherit;
      order: 2;
      -ms-flex-order: 2;
      background: none transparent;
    }
    .close-button{
      padding: 1rem 1rem 0;
      box-sizing: border-box;
      @media #{$medium-and-up} {
        display: none;
      }
    }
    h3{
      padding: 0 1rem;
      box-sizing: border-box;
      @media #{$medium-and-up} {
        display: none;
      }
    }
    ul{
      padding: 0 0 1rem;
      border-bottom: 1px solid color('gray', 'lighten-3');
      @media #{$medium-and-up} {
        @include flexbox();
        @include flex-direction(row);
        padding: 0 0 0 2rem;
        margin: -.25rem 0 0 0;
        border-bottom: 0;
      }
      li{
        padding: 0;
        margin: 0;
        list-style: none;
        a{
          padding: .5rem 1rem .5rem 2rem;
          display: block;
          font-weight: 700;
          font-family: $secondary-font;
          position: relative;
          @media #{$medium-and-up} {
            padding: 0.25rem 1rem .5rem;
            &:hover{
              color: $color-primary;
            }
          }
          &:after{
            content: '';
            width: 8px;
            height: 8px;
            box-sizing: border-box;
            display: block;
            position: absolute;
            left: 12px;
            top: 16px;
            border-right: 1px solid color('gray', 'lighten-3');
            border-top: 1px solid color('gray', 'lighten-3');
            @include transform(rotate(45deg));
            @media #{$medium-and-up} {
              display: none;
            }
          }
        }
      }
    }
    .social-list{
      text-align: center;
      @media #{$medium-and-up} {
        text-align: left;
        padding-left: 3rem;
        padding-top: .25rem;
      }
      a{
        margin: 0 .25rem;
      }
    }
  }
}
