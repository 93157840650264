.date-range{
  .range-content{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 800;
    @include flexbox();
    @include justify-content(center);
    @include align(center);
    .close-bg{
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(color('gray', 'lighten-3'), .75);
      z-index: 900;
    }
    .picker-area{
      z-index: 999;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
