.date-picker-component{
  height: 52px;
  line-height: 1;
  @media #{$medium-and-up} {
    height: 64px;
    line-height: initial;
  }
  &.disable{
    opacity: .5;
    cursor: default;
  }
  &.DARK{
    background: color('gray', 'lighten-2');
    .label-text{
      color: color('gray', 'lighten-3');
    }
    .label-placeholder{
      color: color('gray', 'lighten-3');
    }
    .label-value{
      color: $color-white;
    }
  }
  &.WHITE{
    width: 100%;
    background: $color-white;
    border: 1px solid color('gray', 'lighten-2');
    .label{
      width: 100%;
      @include flex(1);
      .label-container{
        width: 100%;
        @include flex(1)
      }
    }
    .label-text{
      color: $color-primary;
    }
    .label-placeholder{
      color: color('gray', 'lighten-3');
    }
    .label-value{
      color: $color-gray;
    }
  }
  .icon-area{
    width: 41px;
    height: 41px;
    border-radius: 50%;
    @media #{$medium-and-up} {
      width: 32px;
      height: 32px;
    }
    img{
      width: 18px;
      height: 18px;
      @media #{$medium-and-up} {
        width: 24px;
        height: 24px;
      }
    }
  }
}