$footer-background: $color-gray;
$footer-height: 130px;
$footer-height-responsive: 116px;

body{
  padding-bottom: $footer-height-responsive;
  @media #{$medium-and-up} {
    padding-bottom: $footer-height;
  }
}

.main-footer{
  height: $footer-height-responsive;
  background: $footer-background;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 180;
  display: block;
  margin: 0.5rem;
  border-radius: 8px;
  width: calc(100% - 1rem);
  @media #{$medium-and-up} {
    margin: 0;
    width: 100%;
    border-radius: 0;
    height: $footer-height;
    @include flexbox();
    @include flex-direction(row);
  }
  .footer-ctas{
    @media #{$medium-and-up} {
      -ms-flex-order: 1;
      order: 1;
    }
    @media #{$small-and-down} {
      @include justify-content(center)
    }
    .filters-column{
      position: relative;
      @media #{$small-and-down} {
        @include flex(initial)
      }
      .cta-action{
        @media #{$medium-and-up} {
          display: none;
        }
      }
      .filter-selector{
        display: none;
        @media #{$medium-and-down} {
          &.active-filters{
            background: color('gray', 'lighten-1');
            display: block;
            position: fixed;
            left: 8px;
            bottom: 128px;
            padding: .5rem 0;
            border-radius: $radio-default;
            h4{
              padding-left: .5rem;
            }
            .category-list{
              padding-right: 0;
              padding-left: 5px;
              @include flex-wrap(nowrap)
            }
          }
        }
        @media #{$medium-and-up} {
          display: block;
        }
        @media only screen and (max-width : 400px) {
          .category-list{
            .icon-button-component{
              .label-container{
                font-size: 10px!important;
              }
            }
          }
        }
        @media only screen and (max-width : 370px) {
          .category-list{
            .icon-button-component{
              .label-container{
                display: none;
              }
            }
          }
        }
        .icon-button-component{
          .label-container{
            @media screen and (min-width : 1024px) and (max-width: 1180px) {
              display: none;
            }
          }
        }
      }
    }
    .by-date{
      display: none;
      @media #{$medium-and-up} {
        @include flexbox();
        .date-picker-component{
          width: 118px;
        }
      }
    }
    .phone-column{
      @media #{$medium-and-up} {
        display: none;
      }
    }
    .cta-action{
      height: 72px;
      line-height: 1;
      padding: 0 .5rem;
      font-size: $fontsize-small;
      @media #{$medium-and-up} {
        padding: 0 1rem;
        height: $footer-height;
        font-size: $fontsize-default;
      }
      &.promo-active{
        position: relative;
        &:after{
          content: '';
          display: block;
          width: calc(100% - 8px);
          height: calc(100% - 8px);
          border: 1px solid color('gray', 'lighten-3');
          position: absolute;
          top: 4px;
          left: 4px;
          border-radius: 4px;
        }
      }
      .icon-cta{
        padding-bottom: .25rem;
        @media #{$medium-and-up} {
          padding-bottom: 1rem;
        }
        img{
          width: 24px;
          height: 24px;
          @media #{$medium-and-up} {
            width: 36px;
            height: 36px;
          }
        }
      }
    }
  }
  .footer-address{
    font-size: $fontsize-small;
    text-align: center;
    height: 44px;
    padding-top: 5px;
    padding-bottom: 5px;
    @include flexbox();
    @include flex-direction(row);
    @include flex-wrap(wrap);
    @include justify-content(center);
    @include align(center);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    @media #{$medium-and-up} {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      -ms-flex-order: 0;
      order: 0;
      @include flex(1);
      height: $footer-height;
      font-size: $fontsize-default;
      @include flex-direction(column);
      padding: 0 1rem;
      background-image: url("/assets/images/alquiler-minicooper.png");
      background-position: right;
      background-repeat: no-repeat;
      padding-right: 8rem;
      text-align: left;
    }
    @media #{$large-aux-and-down} {
      padding-right: 2rem;
      background-position: 200px;
    }
    @media screen and (min-width : 1024px) and (max-width: 1180px) {
      font-size: $fontsize-small;
      padding-left: .5rem;
      padding-right: 1rem;
    }
    @media #{$medium-and-down} {
      padding-right: 0;
    }
    div{
      display: inline-block;
      padding: 0 .25rem;
      line-height: 1;
      &:first-child{
        width: 100%;
        min-width: 100%;
        @media #{$medium-and-up} {
          display: block;
        }
      }
      @media #{$medium-and-up} {
        line-height: 1.5;
        @include flexbox();
        width: 100%;
      }
    }
  }
}
