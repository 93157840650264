.icon-button-component{
  height: 52px;
  line-height: 1;
  background: none transparent;
  @media #{$medium-and-up} {
    height: 64px;
    line-height: initial;
  }
  &.disable{
    opacity: .5;
    cursor: default;
  }
  .icon-area{
    width: 20px;
    height: 20px;
    @media #{$medium-and-up} {
      width: 32px;
      height: 32px;
    }
    img{
      width: 18px;
      height: 18px;
      @media #{$medium-and-up} {
        width: 24px;
        height: 24px;
      }
    }
  }
}