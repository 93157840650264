@font-face {
  font-family: "Rajdhani";
  src: url('../../assets/font/Rajdhani-Medium.ttf');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Rajdhani";
  src: url('../../assets/font/Rajdhani-Bold.ttf');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Rajdhani";
  src: url('../../assets/font/Rajdhani-Light.ttf');
  font-weight: 300;
  font-display: swap;
}
