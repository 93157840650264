//html Style

html {
  font-size: $rem-unit-size;
}

//body Style

body {
  font-size: $fontsize-default;
  color: $font-default-color;
  line-height: $line-height-normal;
  font-family: $primary-font;
  font-weight: 500;
}

// label Style
.label {
  font-family: $label-family;
  font-size: $label-size;
  color: $label-color;
  line-height: $label-line-height;
  font-weight: $label-weight;
  &:focus {
    color: $label-focus-color;
  }
  &.label-error, &.warning {
    color: $error-color;
  }
  &.label-danger, &.danger, &.warning {
    color: $error-color;
  }
  &.label-disable, &.disable {
    color: $label-disable-color;
  }
}

// error Styles

.error{
  font-family: $error-family;
  font-size: $error-size;
  color: $error-color;
  line-height: $error-line-height;
}

// Header Styles

.super-h1{
  font-size: 4vw
}

h1,
.size-h1,
.size-1 {
  font-size: $fontsize-h1;
  margin: 0;
  line-height: $headers-line-height;
  font-family: $headers-font-family;
  @media #{$large-and-down} {
    font-size: $fontsize-h1-responsive;
  }
  @media #{$small-and-down} {
    font-size: $fontsize-h1-responsive-small;
  }
}
h2,
.size-h2,
.size-2 {
  font-size: $fontsize-h2;
  margin: 0;
  line-height: $headers-line-height;
  font-family: $headers-font-family;
}
h3,
.size-h3,
.size-3 {
  font-size: $fontsize-h3;
  margin: 0;
  line-height: $headers-line-height;
  font-family: $headers-font-family;
}

h4,
.size-h4,
.size-4 {
  font-size: $fontsize-h4;
  margin: 0;
  line-height: $headers-line-height;
  font-family: $headers-font-family;
}

h5,
.size-h5,
.size-5 {
  font-size: $fontsize-h5;
  margin: 0;
  line-height: $headers-line-height;
  font-family: $headers-font-family;
}

//fonts family

.primary-font {
  font-family: $primary-font !important;
}

.secondary-font {
  font-family: $secondary-font !important;
}

// Text Styles

em,
.italic {
  font-style: italic;
}

p {
  margin: 0;
}

.strong {
  font-weight: 700;
}
.light {
  font-weight: 300;
}
.regular {
  font-weight: 500;
}

.size-default {
  font-size: $fontsize-default;
}
.size-small {
  font-size: $fontsize-small;
}

.text-lowercase {
  text-transform: lowercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Text Style

.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.align-justify {
  text-align: justify;
}

.align-left-m {
  @media #{$medium-and-down} {
    text-align: left!important;
  }
}
.align-right-m {
  @media #{$medium-and-down} {
    text-align: right!important;
  }
}
.align-center-m {
  @media #{$medium-and-down} {
    text-align: center!important;
  }
}

.align-left-s {
  @media #{$small-and-down} {
    text-align: left!important;
  }
}
.align-right-s {
  @media #{$small-and-down} {
    text-align: right!important;
  }
}
.align-center-s {
  @media #{$small-and-down} {
    text-align: center!important;
  }
}

.self-center {
  align-self: center;
}

.line-height-large {
  line-height: $line-height-large;
}

.line-height-small {
  line-height: $line-height-small;
}
.line-height-default {
  line-height: $line-height-normal;
}

.letter-spacing-0 {
  letter-spacing: 0;
}
.letter-spacing-1x {
  letter-spacing: 1px;
}
.letter-spacing-2x {
  letter-spacing: 2px;
}

.wrap {
  white-space: normal;
}
.no-wrap {
  white-space: nowrap;
}

.overflow-wrap {
  overflow-wrap: break-word;
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.title-block{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    left: 1rem;
    bottom: 0;
    width: calc(100% - 2rem);
    height: 2px;
    background: color('gray', 'lighten-4');
    display: block;
  }
  &:before{
    z-index: 1;
    content: '';
    position: absolute;
    bottom: 0;
    width: 100px;
    height: 2px;
    background: $color-primary;
    display: block;
    left: 1rem;
  }
}