.rent-modal{
  .modal-content{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 800;
    @include flexbox();
    @include justify-content(center);
    @include align(center);
    .close-bg{
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(color('gray', 'lighten-3'), .75);
      z-index: 900;
    }
    .form-area{
      z-index: 999;
      max-width: 640px;
      max-height: 90%;
      overflow-y: auto;
      .block-area{
        @media #{$small-and-down} {
          display: block;
          &:nth-child(1){
            padding: 1rem 1rem 0;
            .features-title{
              padding-top: 0;
            }
            .features{
              padding-top: 0.5rem;
              padding-bottom: 0;
            }
          }
        }
        .close-rent{
          position: absolute;
          left: 1rem;
          top: .5rem;
        }
      }
      @media #{$small-and-down} {
        max-width: 90%;
        display: block;
      }
      img{
        width: 80%;
        height: auto;
        @media #{$small-and-down} {
          width: 60%;
        }
      }
      .form-content{
        border-top-right-radius: $radio-big;
        border-bottom-right-radius: $radio-big;
        @media #{$small-and-down} {
          border-top-right-radius: 0;
          border-bottom-left-radius: $radio-big;
        }
      }
    }
  }
}
