//visibility

.visibility-hidden {
  visibility: hidden;
}

//position

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

//float

.left {
  float: left !important;
}
.right {
  float: right !important;
}

// display

.hide {
  display: none;
}
.block {
  display: block;
}
.display-none {
  display: none !important;
}
.display-flex {
  @include flexBox();
}
.inline-block {
  display: inline-block;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// display responsive

@media #{$medium-and-down} {
  .hide-medium {
    display: none;
  }
}

//flexBox

.flex-column {
  @include flexBox();
  @include flex-direction(column);
}
.flex-row {
  @include flexBox();
  @include flex-direction(row);
  flex-wrap: wrap;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-no-wrap {
  flex-wrap: nowrap;
}
.flex-left {
  @include align(flex-start);
}
.flex-right {
  @include align(flex-end);
}
.flex-middle {
  @include align(center);
}
.flex-end {
  @include justify-content(flex-end);
}
.flex-start {
  @include justify-content(flex-start);
}
.flex-center {
  @include justify-content(center);
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-justify-center {
  display: flex;
  justify-content: center;
}
.flex-justify-right {
  display: flex;
  justify-content: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-05 {
  flex: 0.5;
}
.flex-075 {
  flex: 0.75;
}
.flex-025 {
  flex: 0.25;
}

//clear

.clear-fix {
  clear: both;
}

//width

.width-100 {
  width: 100%;
}
.width-50 {
  width: 50%;
}
.width-auto {
  width: auto;
}
.width-fit-content {
  width: fit-content;
}
.max-width-100 {
  max-width: 100%;
}

//height

.height-100 {
  height: 100%;
}

//radius

.radius-default {
  border-radius: $radio-default !important;
}
.radius-50 {
  border-radius: 50%;
}
.radius-small {
  border-radius: $radio-small !important;
}
.radius-big {
  border-radius: $radio-big !important;
}
.no-radius {
  border-radius: 0 !important;
}

//list

.list-flat,
.list-inline {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-inline {
  li {
    float: left;
  }
}

//cursor

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}

//shadow

.shadow-0 {
  @include box-shadow($z-depth-0);
}
.shadow-1 {
  @include box-shadow($z-depth-1);
}
.shadow-2 {
  @include box-shadow($z-depth-2);
}

//scroll

.app-scroll {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  @media #{$small-and-up} {
    /* width */
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-button {
      width: 6px;
      height: 0;
      opacity: 0;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: $scroll-bar-container-bg;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $scroll-bar-bg;
      border-radius: 3px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $scroll-bar-arrows-bg;
    }
  }
}
