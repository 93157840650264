// primary colors
$color-primary: #FF5100;
$color-secondary: #212121;
$color-gray: #212121;

// aux colors
$color-success: #46b54d;
$color-info: #41e9cd;
$color-warning: #cc8700;
$color-danger: #db1935;

// base colors
$color-white: #ffffff;
$color-black: #000000;
