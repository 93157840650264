@font-face {
  font-family: "Radio Canada";
  src: url('../../assets/font/RadioCanada-Medium.ttf');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Radio Canada";
  src: url('../../assets/font/RadioCanada-Bold.ttf');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Radio Canada";
  src: url('../../assets/font/RadioCanada-Light.ttf');
  font-weight: 300;
  font-display: swap;
}
