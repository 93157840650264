/*** Typography ***/

$primary-font: "Rajdhani", sans-serif;
$secondary-font: "Radio Canada", sans-serif;

$font-default-color: color('gray', 'lighten-1');
// Header Styles

$fontsize-h1: 52px !default;
$fontsize-h1-responsive: 38px !default;
$fontsize-h1-responsive-small: 32px !default;
$fontsize-h2: 28px !default;
$fontsize-h3: 20px !default;
$fontsize-h4: 18px !default;
$fontsize-h5: 16px !default;

$fontsize-default: 14px;
$fontsize-small: 12px;

// line height

$line-height-large: 2;
$line-height-normal: 1.71;
$line-height-small: 1.1;

// labels
$label-size: $fontsize-small;
$label-weight: 700;
$label-family: $secondary-font;
$label-color: $color-gray;
$label-focus-color: $color-gray;
$label-disable-color: color('gray', 'lighten-2');
$label-line-height: 20px;

// error
$error-size: $fontsize-small;
$error-family: $primary-font;
$error-color: $color-warning;
$error-line-height: 20px;

// headers props
$headers-line-height: $line-height-large !default;
$headers-font-family: $secondary-font !default;
