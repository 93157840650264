$aside-title-height: 65px;

@keyframes fadeInAside {
  from {background: rgba($modal-background, $alpha: 0);}
  to {background: rgba($modal-background, $alpha: $modal-background-opacity);}
}
@keyframes enterTopAside {
  from {right: -1300px;}
  to {right: 0;}
}


.aside-layout-component{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
  background: rgba($color-gray, .75);
  animation-name: fadeInAside;
  animation-duration: .6s;
  .layout-content{
    position: absolute;
    right: 0;
    top: 0;
    width: 95%;
    max-width: 900px;
    height: 100%;
    background: $color-white;
    animation-name: enterTopAside;
    animation-duration: .8s;
    .title-block{
      position: relative;
      .close-button{
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
    }
    .aside-content-area{
      height: calc(100vh - 120px);
      overflow: auto;
      @media #{$small-and-up} {
        /* width */
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-button{
          width: 6px;
          height: 0;
          opacity: 0;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: rgba($color-secondary, .1);
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $color-secondary;
          border-radius: 3px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: lighten($color-secondary, 40%);
        }
      }
      @media #{$small-and-down} {
        padding-bottom: 60px;
      }
    }
  }
}
