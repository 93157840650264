//size Icons
.icon-128x {
  display: inline-block;
  width: 128px;
  height: 128px;
  overflow: hidden;
  svg {
    float: left;
    width: 128px;
    height: 128px;
  }
}
.icon-64x {
  display: inline-block;
  width: 64px;
  height: 64px;
  overflow: hidden;
  svg {
    float: left;
    width: 64px;
    height: 64px;
  }
}
.icon-48x {
  display: inline-block;
  width: 48px;
  height: 48px;
  overflow: hidden;
  svg {
    float: left;
    width: 48px;
    height: 48px;
  }
}
.icon-32x {
  display: inline-block;
  width: 32px;
  height: 32px;
  overflow: hidden;
  svg {
    float: left;
    width: 32px;
    height: 32px;
  }
}
.icon-24x {
  display: inline-block;
  width: 24px;
  height: 24px;
  overflow: hidden;
  svg {
    float: left;
    width: 22px;
    height: 24px;
  }
}
.icon-16x {
  display: inline-block;
  width: 16px;
  height: 16px;
  overflow: hidden;
  svg {
    float: left;
    width: 16px;
    height: 16px;
  }
}
