$padding-content: 16vw;

.about{
  .about-image{
    width: 80%;
    max-width: 400px;
    border-bottom-left-radius: 40px;
    border-top-right-radius: 40px;
  }
  .blocks-content{
    @media #{$small-and-up} {
      @include flexbox();
      @include flex-wrap(nograp);
      @include flex-direction(row);
    }
    .block-item{
      @media #{$small-and-up} {
        @include flexbox();
        @include flex(1);
        @include flex-direction(column);
      }
      p{
        padding-right: 1rem;
      }
    }
  }
}