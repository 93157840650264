$modal-background: color('gray', 'base');
$modal-background-inverse: $color-white;
$modal-container-primary-background: $color-white;
$modal-container-inverse-background: color('gray', 'lighten-1');
$modal-background-opacity: .75;
$modal-radius: 8px;
$modal-small-max-width: 642px;
$modal-extra-small-max-width: 368px;
$modal-full-max-width: 780px;

@keyframes fadeIn {
  from {background: rgba($modal-background, $alpha: 0);}
  to {background: rgba($modal-background, $alpha: $modal-background-opacity);}
}
@keyframes enterTop {
  from {top: -1000px;}
  to {top: 0;}
}

.modal-view {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 799;
  width: 100%;
  height: 100%;
  display: none;
  background: rgba($modal-background, $alpha: 0);
  .modal-container {
    z-index: 2;
    border-bottom-left-radius: $modal-radius;
    border-bottom-right-radius: $modal-radius;
    max-height: 98%;
    width: 90%;
    position: relative;
    top: -1000px;
    opacity: 0;
    background: $modal-container-primary-background;
    max-width: $modal-full-max-width;
    padding: 70px 0 2rem;
    margin-left: auto;
    margin-right: auto;
    @include transition(all .8s);
    @media #{$small-and-down} {
      width: 98%;
      padding: 70px 0 1rem;
    }
  }
  .close-area{
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .modal-header{
    position: absolute;
    top: 1rem;
    right: 0;
    width: 100%;
    padding: 0 2rem;
    @media #{$small-and-down} {
      padding: 0 1rem;
    }
    .close-button{
      position: absolute;
      right: 1rem;
      top: 0;
    }
  }
  .modal-body{
    overflow-y: auto;
    max-height: calc(100vh - 130px);
    padding: 0 2rem;
    @media #{$small-and-down} {
      padding: 0 1rem 60px;
    }
  }
  &.modal-active{
    display: block;
    animation-name: fadeIn;
    animation-duration: .6s;
    background: rgba($modal-background, $alpha: $modal-background-opacity);
    .modal-container{
      top: 0;
      opacity: 1;
      animation-name: enterTop;
      animation-duration: .8s;
    }
  }
}
