.vehicle-card-component {
  position: relative;
  &:before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    border-top-left-radius: $radio-default;
    border-bottom-left-radius: $radio-default;
    width: 120px;
    height: 100%;
    background: color('gray', 'base');
  }
  .flex-row{
    z-index: 2;
  }
  .price-area{
    width: 120px;
  }
  .image-area{
    width: 200px;
  }
}