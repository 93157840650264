$alerts-radius: 5px;

.alert-view{
  z-index: 999;
  position: fixed;
  right: 0;
  top: 0;
  .alert-item{
    padding: .7rem 1rem 1rem;
    background: $color-white;
    border-radius: $alerts-radius;
    text-align: center;
    margin-top: 1rem;
    margin-right:  1rem;
    white-space: nowrap;
    width: 300px;
    @include box-shadow($z-depth-1);
    position: relative;
    flex-wrap: nowrap;
    .icon-area{
      height: 24px;
      width: 24px;
      min-width: 24px;
    }
    .icon-close{
      height: 10px;
      width: 10px;
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }
}
